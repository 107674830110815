<template>
  <div class="content-box">
      <Title/>

      <Project/>
  </div>
</template>

<script>
import { Project } from 'shared'

export default {
  name: 'project',
  components: {
      Project
  },
  created() {
      this.$store.dispatch('getProject', '房产')
  }
}
</script>